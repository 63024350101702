<template>
  <component is="PostList"  postType="boxMail"/>
</template>

<script>
import PostList from './PostList'

export default {
  name: "BoxMailList",
  components: {
    PostList
  }
}
</script>

<style scoped>

</style>